import { Calendar } from '@fullcalendar/core'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import huLocale from '@fullcalendar/core/locales/hu'
import interactionPlugin from '@fullcalendar/interaction'
import listPlugin from '@fullcalendar/list'
import bulmaPlugin from './BulmaTheme'

// Return true if device is mobile and has portrait mode
function isPortraitMobile () {
  const isPortrait = (window.screen.width / window.screen.height) < 1
  const isMobileWidth = window.matchMedia('(max-width: 767px)').matches
  return isPortrait && isMobileWidth
}

const defaults = {
  canManageEvents: false,
  initialDate: null,
  // Make agenda view default on mobile devices with portrait mode
  initialView: isPortraitMobile() ? 'listWeek' : 'timeGridWeek',
  locale: 'en',
  canManageDailyAttendance: false,
  dailyAttendanceText: 'Full day absences',
  eventSources: []
}

// Define a custom element for full calendar component
customElements.define('full-calendar', class extends HTMLElement {
  constructor () {
    super()
    this._initialDate = defaults.initialDate
    this._initialView = defaults.initialView
    this._locale = defaults.locale
    this._eventSources = defaults.eventSources
    this._canManageEvents = defaults.canManageEvents
    this._canManageDailyAttendance = defaults.canManageDailyAttendance
    this._dailyAttendanceText = defaults.dailyAttendanceText
  }

  get initialDate () {
    return this._initialDate
  }

  set initialDate (value) {
    if (value === '') return defaults.initialDate
    if (this._initialDate === value) return
    this._initialDate = value
    if (!this._calendar) return
    this._calendar.gotoDate(value)
  }

  get initialView () {
    return this._initialView
  }

  set initialView (value) {
    if (value === '') return defaults.initialView
    if (this._initialView === value) return
    this._initialView = value
    if (!this._calendar) return
    this._calendar.changeView(value)
  }

  get locale () {
    return this._locale
  }

  set locale (value) {
    if (value === '') return defaults.locale
    if (this._locale === value) return
    this._locale = value
    if (!this._calendar) return
    this._calendar.setOption('locale', value)
  }

  get dailyAttendanceText () {
    return this._dailyAttendanceText
  }

  set dailyAttendanceText (value) {
    if (value === '') return defaults.dailyAttendanceText
    if (this._dailyAttendanceText === value) return
    this._dailyAttendanceText = value
  }

  get canManageDailyAttendance () {
    return this._canManageDailyAttendance
  }

  set canManageDailyAttendance (value) {
    if (value === '') return defaults.canManageDailyAttendance
    if (this._canManageDailyAttendance === value) return
    this._canManageDailyAttendance = value
  }

  get canManageEvents () {
    return this._canManageEvents
  }

  set canManageEvents (value) {
    if (value === '') return defaults.canManageEvents
    this._canManageEvents = value
    if (!this._calendar) return
    this._calendar.setOption('eventResizableFromStart', value)
    this._calendar.setOption('eventDurationEditable', value)
    this._calendar.setOption('eventStartEditable', value)
  }

  get eventSources () {
    return this._eventSources
  }

  // Update event sources.
  //
  // If calendar is available, purge obsolete source and add new ones. Otherwise
  // just save the value that will be used for calendar initialization.
  set eventSources (value) {
    if (value === '') return defaults.eventSources
    const obsoleteSources = this._eventSources.filter((source) => {
      return !(source.id in value.keys())
    })
    const newSources = value.filter((source) => {
      return !(source.id in this._eventSources.keys())
    })

    this._eventSources = value
    if (!this._calendar) return

    obsoleteSources.forEach((source) => {
      const s = this._calendar.getEventSourceById(source.id)
      if (!s) return
      s.remove()
    })
    newSources.forEach((source) => {
      this._calendar.addEventSource(source)
    })
  }

  // Initialize Full Calendar when DOM node is available
  connectedCallback () {
    this._calendar = new Calendar(this, {
      plugins: [dayGridPlugin, timeGridPlugin, bulmaPlugin, interactionPlugin, listPlugin],
      initialView: this._initialView,
      headerToolbar: {
        left: 'prev,next today',
        center: 'title',
        right: 'dayGridMonth,timeGridWeek,timeGridDay,listWeek'
      },
      navLinks: true,
      weekends: false,
      themeSystem: 'bulma',
      aspectRatio: isPortraitMobile() ? 0.9 : 1.35,
      slotMinTime: '08:00:00',
      slotMaxTime: '20:00:00',
      allDaySlot: false,
      selectable: this._canManageEvents,
      selectMirror: this._canManageEvents,
      initialDate: this._initialDate,
      locales: [huLocale],
      locale: this._locale,
      dailyAttendanceText: this._dailyAttendanceText,
      firstDay: 1,
      timeZone: 'UTC',
      eventResizableFromStart: this._canManageEvents,
      eventDurationEditable: this._canManageEvents,
      eventStartEditable: this._canManageEvents,
      eventSources: this._eventSources,

      views: this.canManageDailyAttendance
        ? {
            timeGrid: { // applies to all 'timeGrid' views, including 'timeGridWeek' and 'timeGridDay'
              dayHeaderDidMount: (arg) => { this._addAbsenceButtonToTimeGridDayHeaders(arg) }
            },
            list: {
              dayHeaderDidMount: (arg) => { this._addAbsenceButtonToListDayHeaders(arg) }
            }
          }
        : {}
    })
    this._registerCustomEvents()
    this._calendar.render()
  }

  // Dispatch custom events listened to by the Elm app on UI interactions
  _registerCustomEvents () {
    this._calendar.on('select', (info) => this.timeslotSelectHandler(info))
    this._calendar.on('eventClick', (info) => this.eventClickHandler(info))
    this._calendar.on('datesSet', (info) => this.viewChangeHandler(info))
    this._calendar.on('eventResize', (info) => this.eventTimeChangeHandler(info))
    this._calendar.on('eventDrop', (info) => this.eventTimeChangeHandler(info))
  }

  _addAbsenceButtonToElement (element, date) {
    const a = document.createElement('a')
    a.target = '_self'
    a.title = this.dailyAttendanceText
    a.style.cssFloat = 'right'
    a.href = 'https://intezmenykereso.e-kreta.hu/#'
    const span = document.createElement('span')
    span.className = 'icon has-text-info'
    const i = document.createElement('i')
    i.className = 'fas fa-user'
    i.setAttribute('aria-hidden', 'true')

    span.appendChild(i)
    a.appendChild(span)
    element.appendChild(a)
  }

  _addAbsenceButtonToTimeGridDayHeaders (arg) {
    return this._addAbsenceButtonToElement(arg.el.firstChild, arg.date)
  }

  _addAbsenceButtonToListDayHeaders (arg) {
    return this._addAbsenceButtonToElement(arg.el.firstChild.firstChild, arg.date)
  }

  _fullDayAbsenceHandler (date, event) {
    const detail = { date: date.toISOString() }
    return this.dispatchEvent(new CustomEvent('fullDayAbsenceButtonClicked', { detail }))
  }

  viewChangeHandler (info) {
    const detail = {
      currentDate: this._calendar.getDate().toISOString(),
      viewStart: info.start.toISOString(),
      viewEnd: info.end.toISOString(),
      viewType: this._calendar.getCurrentData().currentViewType
    }
    this.dispatchEvent(new CustomEvent('viewChanged', { detail }))
  }

  timeslotSelectHandler (info) {
    // We only support same-day events
    if (info.allDay === true) return this._calendar.unselect()
    const detail = {
      start: info.start.toISOString(),
      end: info.end.toISOString()
    }
    this.dispatchEvent(new CustomEvent('timeSlotSelected', { detail }))
  }

  eventClickHandler (info) {
    this.dispatchEvent(new CustomEvent('eventClicked', { detail: info.event.id }))
  }

  eventTimeChangeHandler (info) {
    console.log(info.event.start.toISOString())
    const detail = {
      eventId: info.event.id,
      start: info.event.start.toISOString(),
      end: info.event.end.toISOString()
    }
    this.dispatchEvent(new CustomEvent('eventTimeChanged', { detail }))
  }
})
