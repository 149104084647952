import { Theme, createPlugin } from '@fullcalendar/common'

export class BulmaTheme extends Theme {
}

BulmaTheme.prototype.classes = {
  root: 'fc-theme-standard',
  table: 'table-bordered',
  tableCellShaded: 'table-active',
  buttonGroup: 'fc-button-group',
  button: 'button is-grey is-light',
  buttonActive: 'is-active',
  popover: 'popover',
  popoverHeader: 'popover-header',
  popoverContent: 'popover-body'
}

BulmaTheme.prototype.baseIconClass = 'fa'
BulmaTheme.prototype.iconClasses = {
  close: 'fa-times',
  prev: 'fa-chevron-left',
  next: 'fa-chevron-right',
  prevYear: 'fa-angle-double-left',
  nextYear: 'fa-angle-double-right'
}
BulmaTheme.prototype.rtlIconClasses = {
  prev: 'fa-chevron-right',
  next: 'fa-chevron-left',
  prevYear: 'fa-angle-double-right',
  nextYear: 'fa-angle-double-left'
}

const plugin = createPlugin({
  themeClasses: {
    bulma: BulmaTheme
  }
})

export default plugin
