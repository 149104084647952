import logoPath from './bps_logo.webp'
import { Elm } from './Main.elm'
import registerServiceWorker from './registerServiceWorker'
import './FullCalendar'
import './EasyMDE'
require('dotenv').config({ path: './.env' })

// A workaround to avoid app crash when Google Translate extension is used.
// Must be loaded before the app is initialized.
//
// https://insurello.se/how-we-made-elm-and-google-translate-work-together/
HTMLFontElement.prototype.replaceData =
  function replaceData (_0, _1, string) {
    this.parentNode.replaceChild(
      document.createTextNode(string),
      this
    )
  }

//
// Config
//
const config = {
  productionUrl: 'my.budapestschool.org',
  logAppNameBackend: 'my_bps',
  logAppNameClient: '[MYBPS]',
  gaMeasurementCode: 'G-9BYV5RPY4B'
}

//
// Init app
//
const lastRequestedUrl = sessionStorage.getItem('lastRequestedUrl')
const app = Elm.Main.init({
  node: document.getElementById('root'),
  flags: {
    lastRequestedUrl: lastRequestedUrl,
    auth0Domain: process.env.ELM_APP_AUTH0_DOMAIN,
    auth0ClientId: process.env.ELM_APP_AUTH0_CLIENT_ID,
    auth0Callback: process.env.ELM_APP_AUTH0_REDIRECT_URL,
    apiBase: process.env.ELM_APP_BACKEND_API,
    logAppName: config.logAppNameBackend,
    logMaxLength: 1024,
    logoPath: logoPath,
    buildRev: process.env.ELM_APP_BUILD_REVISION || 'dev'
  }
})

//
// Set up Google Analytics tracking
//
if (window.location.hostname === config.productionUrl) {
  window.dataLayer = window.dataLayer || []
  window.gtag = function () { window.dataLayer.push(arguments) }
  window.gtag('js', new Date())
}

//
// Ports
//
app.ports.saveLastRequestedUrl.subscribe(function (data) {
  console.debug(config.logAppNameClient, 'storing last requested URL', data)
  sessionStorage.setItem('lastRequestedUrl', data)
})

app.ports.consoleError.subscribe(function (data) {
  console.error(config.logAppNameClient, data)
})

app.ports.consoleInfo.subscribe(function (data) {
  console.info(config.logAppNameClient, data)
})

// Sends a page view to Google Analytics
app.ports.sendPageView.subscribe(function (data) {
  if (typeof window.gtag !== 'function') return
  window.gtag('config', config.gaMeasurementCode, {
    page_path: data.pagePath,
    page_title: data.pageTitle
  })
})

// Scrolls to given element
app.ports.scrollToElement.subscribe(function (selector) {
  const el = document.querySelector(selector)
  if (el) {
    el.scrollIntoView({ behavior: 'smooth' })
  }
})

// Set the website's language through setting the "lang" property on the root
// "html" element.
app.ports.setPageLanguage.subscribe(function (lang) {
  document.querySelector('html').lang = lang
})

// Find cookie with given name and return its value
const getCookie = function (name) {
  const cookies = document.cookie.split('; ')
  return cookies
    .map((cookieStr) => cookieStr.split('='))
    .filter((cookie) => cookie[0] === name)
    .map((cookie) => cookie[1])
    .reduce((acc, val) => val, null)
}

// Read CSRF token from cookie
app.ports.fetchCsrfToken.subscribe(function (str) {
  const value = getCookie('csrftoken')
  if (value === null) {
    app.ports.csrfTokenReciever.send('')
  } else {
    app.ports.csrfTokenReciever.send(value)
  }
})

// Load the manifest.json
app.ports.loadManifest.subscribe((manifestUrl) => {
  const manifestRel = document.getElementById('webmanifest')
  manifestRel.setAttribute('href', manifestUrl)
})

// Remove the manifest.json reference
app.ports.resetManifest.subscribe(() => {
  const manifestRel = document.getElementById('webmanifest')
  manifestRel.removeAttribute('href')
})

//
// Service worker
//
registerServiceWorker(config, app.ports)
