import EasyMDE from 'easymde'

// A custom element to wrap Easy Markdown Editor.
//
// Ideally this component could extend the built-in HTMLTextAreaElement but
// there's no support for that in WebKit and appearently Apple refuses to add
// it. [0]
//
// Also, there's no support from that on Elm's side either. [1]
//
// [0]: https://github.com/w3c/webcomponents/issues/509#issuecomment-233419167
// [1]: https://github.com/elm/virtual-dom/issues/156
class EasyMdeElement extends HTMLElement {
  constructor () {
    super()
    this._easyMDE = null
    this._initial = null
    this._placeholder = null
    this._root = this.attachShadow({ mode: 'open' })

    // Keep track of content value for delayed event dispatch
    this._value = null

    const mdeStyle = document.createElement('link')
    mdeStyle.setAttribute('rel', 'stylesheet')
    mdeStyle.setAttribute('href', 'https://unpkg.com/easymde@2.15.0/dist/easymde.min.css')
    this._root.appendChild(mdeStyle)

    const faStyle = document.createElement('link')
    faStyle.setAttribute('rel', 'stylesheet')
    faStyle.setAttribute('href', 'https://use.fontawesome.com/releases/v5.14.0/css/all.css')
    this._root.appendChild(faStyle)

    this._textarea = document.createElement('textarea')
    this._root.appendChild(this._textarea)
  }

  get initial () {
    return this._initial
  }

  set initial (value) {
    this._initial = value
  }

  get placeholder () {
    return this._placeholder
  }

  set placeholder (value) {
    this._placeholder = value
  }

  // Initialize EasyMDE when DOM node is available
  connectedCallback () {
    const options = {
      minHeight: '200px',
      maxHeight: '200px',
      hideIcons: ['heading', 'quote', 'image', 'preview', 'guide'],
      initialValue: this._initial,
      placeholder: this._placeholder,
      element: this._textarea,
      status: false,
      spellChecker: false
    }
    this._easyMDE = new EasyMDE(options)

    // Expode parts of the shadow elements to be able to style them form the outside
    const container = this._root.querySelector('.EasyMDEContainer')
    container.querySelector('.cm-s-easymde').setAttribute('part', 'easymde-editor')
    container.querySelector('.editor-toolbar').setAttribute('part', 'easymde-toolbar')
    this._adjustHostHeight()

    // Will be fired whenever CodeMirror updates its DOM display
    this._easyMDE.codemirror.on('update', () => {
      this._value = this._easyMDE.value()
      if (this._value === this._initial) {
        return
      }
      this._dispatchContentChanged(this._value)
    })

    this._easyMDE.codemirror.on('viewportChange', () => {
      this._adjustHostHeight()
    })
  }

  // Send the changed content value the Elm app once the user has finished
  // typing (=didn't change the content for 0.5 second).
  _dispatchContentChanged (value) {
    const delay = 500
    const dispatcher = () => {
      if (this._value === value) {
        this.dispatchEvent(new CustomEvent('contentChanged', { detail: this._easyMDE.value() }))
      }
    }
    window.setTimeout(dispatcher, delay)
  }

  // Set the height of the host to the container's height
  _adjustHostHeight () {
    const container = this._root.querySelector('.EasyMDEContainer')
    const topBorder = getComputedStyle(this)['border-top-width']
    const bottomBorder = getComputedStyle(this)['border-bottom-width']
    const containerHeight = container.offsetHeight + 'px'
    this.style.height = `calc(${containerHeight} + ${topBorder} + ${bottomBorder})`
  }
}

customElements.define('rich-text-editor', EasyMdeElement)
